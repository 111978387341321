<template>
  <div class="box">
    <div class="time">{{fn(message.timeCreate)}}</div>
    <div class="center">
      <p>{{message.title}}</p>
       <p>{{message.content}}</p>
    </div>
  </div>
</template>

<script>
import {filterTime} from "../utils/utils";
export default {
 props:['message' ],
    methods:{
        fn(val){
         return filterTime(val);
        }
    },
}
</script>

<style scoped lang="scss">
@import "../styles/initialization.scss";
.box {
  .time {
    margin-top: 30px;
    margin-bottom: 15px;
    height: 14px;
    font-size: 12px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }
  .center {
    width: 420px;
    height: 120px;
    background: #f7f7f7;
    border-radius: 10px;
    padding: 26px 0px;
    box-sizing: border-box;
    p{
         margin-left:30px;
    }
    p:nth-child(1) {
     
      height: 14px;
      font-size: 16px;
      font-family: PingFangHK-Medium, PingFangHK;
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      
    }
    p:nth-child(2) {
        margin-top:10px;
        margin-bottom: 4px;
        height: 20px;
        font-size: 14px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
    }
    p:nth-child(3) {
        height: 20px;
        font-size: 14px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #666666;
        line-height: 20px;
    }
  }
}
</style>

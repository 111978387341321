<template>
  <div class="demo">
    <div class="leftnav">
      <ul
        class="newbox"
        :class="-2 == switchmessage ? 'effects' : ''"
       
      >
        <li class="image">
           <img src="../assets/examine/systemmessage.png" />
        </li>
        <li class="message">
          <p><span>系统公告</span> <span>{{time}}</span></p>
          <p>{{conter}}</p>
        </li>
      </ul>
      <!-- 钱包 -->
      <!-- <ul
        class="newbox"
        :class="-1 == switchmessage ? 'effects' : ''"
      >
        <li class="image"><img src="../assets/examine/walletmessage.png" /></li>
        <li class="message">
          <p><span>钱包消息</span> <span>1分钟前</span></p>
          <p>{{conter}}</p>
        </li>
      </ul> -->
    </div>
    <div class="contentbox">
      <ul class="headtitle">
        <li v-if="switchmessage == -2">系统消息</li>
        <li else v-if="switchmessage == -1">钱包通知</li>
        <li @click="go" style="cursor:pointer">&lt;返回</li>
      </ul>
      <div class="newsbox"  ref="newboxSyte">
      <div class="bii"  v-if="switchmessage == -2">
      <SystemMessage v-for="(item , index) in listarr" :key = "index" :message = "item"  ></SystemMessage>
      </div>
       <div class="bii"  v-if="switchmessage == -1">
              <Walletmessage v-for="(item , index) in listarr" :key = "index" :message = "item" ></Walletmessage>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Walletmessage from '../components/walletmessage.vue'
import SystemMessage from '@/components/Systemmessage.vue'
import {filterTime} from "../utils/utils";
var scrollTopHeight=0;
export default {
  components: {
    Walletmessage,
    SystemMessage
  },
  mounted() {
   this.newsmessage();
   this.$nextTick(
       ()=>{
        this.$refs.newboxSyte.addEventListener("scroll", this.handleScroll,false)
       }
   );
  },
  data() {
    return {
      //  切换消息
      switchmessage: -2,
    // 数据
    listarr:[],
    conter:'',
    time:"",
    flag:true,
    pageSize:10,
    type:0,
    total: 0
    }
  },
  methods: {
    // 切换钱包与系统消息函数添加添加 newbox ul 标签点击事件
    switchnews(val) {
      this.$refs.newboxSyte.addEventListener("scroll",this.handleScroll,false)
       if(val==-2){
         this.pageSize=10
          this.type=0
            this.newsmessage()
            this.flag=true
       } else if(val==-1){
           this.type=3
           this.pageSize=10
           this.newsmessage()
           this.flag=true
       }
      this.switchmessage = val
    },
    // 获取消息
    newsmessage(){
        this.$post('/api/message/getSysMessages',{
            "pageNum": 1,
            "pageSize":this.pageSize,
            "type":this.type
        }).then((res)=>{
           if( res.data.list.length > 0 ){
                this.conter=res.data.list[0].content
                this.listarr=res.data.list
                this.time=filterTime(res.data.list[0].timeCreate)
                this.total= res.data.total
                 if(this.pageSize>=this.total){
                    this.$refs.newboxSyte.removeEventListener("scroll", this.handleScroll,false)
                      this.$popup({
                            title: '成功',
                            msg: '消息已全部加载完成',
                            type: 'success',
                            duration:'800'
                            }); 
                            scrollTopHeight=0
                  }
                // 消息已读
                let message =[]
                 res.data.list.forEach((item)=>{
                 if(item.state==0){
                     message.push(item.id)
                 }
              })
              if(message.length>0){
                  this.$post('/api/message/messageBack',{msgIds:message.join(',')}).then(res=>{ 
                 console.log(res,'消息已读')
                   let nes= Number(this.$store.state.unReadTotalNum)-Number(message.length);
                  if(nes>0){
                      this.$store.state.unReadTotalNum = nes;
                  }else{
                      this.$store.state.unReadTotalNum = ''
                  }
              })
              }
           
           }else{
               this.listarr=[]
           }
        })
    },
    handleScroll(){
    this.$nextTick(()=>{
    var newbox= document.documentElement.getElementsByClassName('newsbox')[0]; 
       var bii= document.documentElement.getElementsByClassName('bii')[0];
            if(newbox.offsetHeight+newbox.scrollTop>=bii.offsetHeight){
                if(scrollTopHeight>newbox.offsetHeight+newbox.scrollTop){
                    return
                }
                if(this.flag){
                    let _this= this;
                    this.flag=false;
                        setTimeout(function(){
                        _this.pageSize=_this.pageSize+10
                        _this.newsmessage()
                        _this.flag=true;
                        scrollTopHeight=newbox.offsetHeight+newbox.scrollTop;
                    },800)
                  
                }
            }else{
                return
            }
            
    });
    },
    go(){
        this.$router.go(-1)
    },
}
}
</script>

<style scoped lang="scss">
@import '../styles/initialization.scss';
.demo {
  display: flex;
  justify-content: center;

  .leftnav {
    box-sizing: border-box;
    margin-top: 44px;
    margin-bottom: 60px;
    background: #ffffff;
    width: 266px;
    .newbox {
      height: 89px;
      box-sizing: border-box;
      border: 1px solid transparent;
      display: flex;
      align-items: center;
      .image {
        width: 40px;
        height: 40px;
        margin-right: 12px;
        margin-left: 26px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .message {
        box-sizing: border-box;
        height: 40px;
        width: 168px;
        p:nth-child(1) {
          margin-bottom: 10px;
          height: 14px;
          font-size: 16px;
          font-family: PingFangHK-Regular, PingFangHK;
          font-weight: 400;
          color: #333333;
          line-height: 14px;
          display: flex;
          justify-content: space-between;
          span {
            display: inline-block;

          }
          span:nth-child(2) {
            height: 14px;
            font-size: 10px;
            font-family: PingFangHK-Regular, PingFangHK;
            font-weight: 400;
            color: #999999;
            line-height: 14px;
             overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
            width: 90px;
            text-align: right;
          }
        }
        p:nth-child(2) {
          height: 14px;
          font-size: 12px;
          font-family: PingFangHK-Regular, PingFangHK;
          font-weight: 400;
          color: #666666;
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
    .effects {
      background-color: #f5f4f4;
      border: 1px solid #e8e8e8;
    }
  }
  .contentbox {
    margin-left: 20px;
    margin-top: 44px;
    margin-bottom: 60px;
    width: 1200px;
    min-width: 920px;
    background: #ffffff;
    border: 1px solid #e8e8e8;
    box-sizing: border-box;
    padding: 0 20px;
    .headtitle {
      margin: 0px auto;
      border-bottom: 1px solid #e5e5e5;
      height: 32px;
      display: flex;
      justify-content: space-between;
      margin-top: 26px;
      li:nth-child(1) {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      li:nth-child(2) {
        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ff004d;
        line-height: 20px;
      }
    }
    .newsbox {
      overflow-y: scroll;
      height: 800px;
      width: 920px;
    }
    .newsbox::-webkit-scrollbar{
      width:10px;
    }
    .newsbox::-webkit-scrollbar-thumb{
      background: #bfbfbf;
      border-radius:10px;
    }
  }
}
</style>

<template>
  <div class="systembox">
    <div class="time">{{fn(message.timeCreate)}}</div>
    <div class="centerbox">
      <p class="title">
        {{message.title}}
      </p>
      <div class="textcontent">
        <!-- <p class="smalltitle">一、活动目的</p> -->
        <div class="smallcontent">
         {{message.content}}
        </div>
      </div>
      <!-- <div class="textcontent">
           <p class="smalltitle">二、活动目的</p>
           <div class="smallcontent">本次“歌唱北京”共分为三个系列活动，分别为首都市民合唱节、器乐比赛、我爱唱歌京津冀歌手比赛。 （一）首都市民合唱节共五项赛事：阿卡贝拉组、合唱年度示范组、合唱原创作品组、合唱中青年组、合唱老年组五个组别的比赛； （二）全市器乐大赛共三项赛事：民族器乐团体组比赛、西洋器乐团体组比赛、打击乐团体比赛； （三）京津冀歌手共四项赛事：少年组、青年组、中年组和老年组四个组别的比赛。</div>
      </div> -->
    </div>
  </div>
</template>

<script>
import {filterTime} from "../utils/utils";
export default {
    props:["message"],
    data() {
        return {
        }
    },
    mounted() {
    },
    methods:{
        fn(val){
         return filterTime(val);
        }
    },
}
</script>

<style scoped lang="scss">
@import '../styles/initialization.scss';
.systembox {
  .time {
    margin-top: 30px;
    margin-bottom: 15px;
    height: 14px;
    font-size: 12px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #666666;
    line-height: 14px;
  }
  .centerbox {
    width: 420px;
    box-sizing: border-box;
    background: #f7f7f7;
    border-radius: 10px;
    padding: 26px 30px;
    .title {
      height: 14px;
      font-size: 16px;
      font-family: PingFangHK-Medium, PingFangHK;
      font-weight: 600;
      color: #333333;
      line-height: 14px;
      margin-bottom: 16px;
    }
    .textcontent {
      .smalltitle {
        font-size: 14px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
      }
      .smallcontent{
        font-size: 14px;
        font-family: PingFangHK-Regular, PingFangHK;
        font-weight: 400;
        color: #333333;
        line-height: 20px; 
      }

    }
  }
}
</style>
